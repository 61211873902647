import _toConsumableArray from "/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/toConsumableArray.js";
import _defineProperty from "/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
var __jsx = React.createElement;
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
/**
 * Represents Hero Carousel Component.
 * @author kkum
 */import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import analyticsConstants from '../../../constants/analyticsConstants';
import constants from '../../../constants/globalConstants';
import { siteConstants } from '../../../constants/globalConstants';
import MediaTextCarousel from '../../molecules/MediaTextCarousel';
import { getImageRenditions, getImageRenditionsVariationC } from './HeroCarouselUtility';
import styles from './HeroCarousel.style';
import { useContentfulLiveUpdates } from "@contentful/live-preview/react";
var HeroCarousel = function HeroCarousel(_ref) {
  var heroCarouselData = _ref.heroCarouselData,
    className = _ref.className,
    shouldPreloadImage = _ref.shouldPreloadImage;
  var _useState = useState(0),
    windowInnerWidth = _useState[0],
    setwindowInnerWidth = _useState[1];
  useEffect(function () {
    var updateWindowDimensions = function updateWindowDimensions() {
      var newWidth = window.innerWidth;
      setwindowInnerWidth(newWidth);
    };
    updateWindowDimensions();
    window.addEventListener('resize', updateWindowDimensions);
    return function () {
      return window.removeEventListener('resize', updateWindowDimensions);
    };
  }, []);
  if (!heroCarouselData) {
    return null;
  }
  var dto = {};
  var variation = heroCarouselData.variation.toLowerCase();
  var height = heroCarouselData.height && heroCarouselData.height.toLowerCase() || 'large';
  var mobileHeroVariation = heroCarouselData.mobileHeroVariation && heroCarouselData.mobileHeroVariation.toLowerCase() || 'split';
  dto.videoSettings = {
    autoplay: '1',
    controls: '0',
    loop: '1',
    fs: '0',
    mute: '1'
  };
  dto.variationClass = variation;
  dto.analyticsAnchorCTA = analyticsConstants.heroCarousel;
  dto.styleType = 'heroCarousel';
  dto.descriptionType = siteConstants.RICH_TEXT;
  dto.srcSetSetting = {
    imageRenditions: variation === constants.variations.variationC.toLocaleLowerCase() ? getImageRenditionsVariationC(height, mobileHeroVariation) : getImageRenditions(height, mobileHeroVariation),
    quality: 75
  };
  var data = _objectSpread(_objectSpread(_objectSpread({}, heroCarouselData), dto), {}, {
    cardsCollection: _objectSpread(_objectSpread({}, heroCarouselData.cardsCollection), {}, {
      items: _toConsumableArray(heroCarouselData.cardsCollection.items.map(function (item) {
        return useContentfulLiveUpdates(item);
      }))
    })
  });
  return __jsx("div", {
    className: "nva-hero-carousel nva-hero-carousel--".concat(variation, " hero-carousel ").concat(className)
  }, __jsx(MediaTextCarousel, {
    className: "nva-hero-carousel__media-text-carousel",
    mediaTextCarouselData: data,
    height: height,
    mobileHeroVariation: mobileHeroVariation,
    isHero: true,
    isGreaterThanPhone: windowInnerWidth > 767,
    isLessThanDesktop: windowInnerWidth < 1024,
    shouldPreloadImage: shouldPreloadImage
  }));
};
HeroCarousel.defaultProps = {
  className: ''
};
export default styled(HeroCarousel).withConfig({
  componentId: "sc-7us19m-0"
})(["", ";"], styles);