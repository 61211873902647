import _extends from "/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
/**
 * Represents Five Card Component.
 * @author Harmeet
 */import React from 'react';
import Carousel from '../../molecules/Carousel';
import ImageLink from '../../molecules/ImageLink/ImageLink';
import constants from '../../../constants/globalConstants';
import CarouselArrow from '../../atoms/CarouselArrow';
import { imageRenditions, imageRenditionsEquine, imageRenditionsVariationB } from './config';
import styled from 'styled-components';
import styles from './FiveCard.style';
var FiveCardBlock = styled.section.withConfig({
  componentId: "sc-1mdi61j-0"
})(["", ";"], styles);
var FiveCard = function FiveCard(_ref) {
  var fiveCardData = _ref.fiveCardData,
    className = _ref.className,
    themeName = _ref.themeName;
  if (!fiveCardData) {
    return null;
  }
  var Settings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    speed: 500,
    infinite: true,
    dots: true,
    prevArrow: __jsx(CarouselArrow, {
      type: "previous"
    }),
    nextArrow: __jsx(CarouselArrow, {
      type: "next"
    })
  };
  var variation = fiveCardData.variation;
  var variationClass = "".concat(variation.toLowerCase(), " ");
  var cards = fiveCardData && fiveCardData.cardCollection && fiveCardData.cardCollection.items ? fiveCardData.cardCollection.items : [];
  var imageRenditionsSettings = variation === constants.variations.variationB ? imageRenditionsVariationB : themeName === 'Equine' ? imageRenditionsEquine : imageRenditions;
  var anchorLink = fiveCardData && (fiveCardData === null || fiveCardData === void 0 ? void 0 : fiveCardData.anchorLink) || '';
  var attributes = {};
  if (anchorLink != '') attributes.id = anchorLink;
  if (className) attributes.className = "nva-5-card ".concat(className);
  return __jsx(FiveCardBlock, attributes, __jsx("div", {
    className: "nva-5-card__wrapper organism five-card container"
  }, __jsx(Carousel, _extends({
    className: "nva-5-card__carousel ".concat(variationClass),
    styleType: "5cardCarousel"
  }, Settings), cards.map(function (carddata, index) {
    var _carddata$targetUrl;
    var imgDetails = carddata.backgroundImage;
    var classNameHeading = fiveCardData.variation === constants.variations.variationB ? 'slick_header' : 'slick_header arrow';
    var renderSVG = fiveCardData.variation === constants.variations.variationB ? true : false;
    var anchorLink = carddata.anchorLink;
    var dto = {
      renderSVG: renderSVG,
      anchorLink: anchorLink,
      classNameAnchor: 'nva-5-card__anchor slick_anchor',
      classNameImage: 'nva-5-card__image slick_image',
      imageDetails: imgDetails,
      classNamePara: 'nva-5-card__text slick_para',
      ParaText: carddata.cardName,
      classNameHeading: "nva-5-card__heading ".concat(classNameHeading, "  heading_four"),
      hrefAnchor: carddata !== null && carddata !== void 0 && (_carddata$targetUrl = carddata.targetUrl) !== null && _carddata$targetUrl !== void 0 && _carddata$targetUrl.includes('/services/') ? "/services/".concat(carddata.targetUrl.substring(10)) : carddata.targetUrl,
      hrefBehavior: carddata.targetUrlBehaviour,
      dataAnalytics: {
        type: '5-card',
        value: carddata.cardName || '',
        variation: variation
      },
      srcSetSetting: {
        imageRenditions: imageRenditionsSettings
      }
    };
    return __jsx(ImageLink, _extends({
      key: index
    }, dto));
  }))));
};
FiveCard.defaultProps = {
  className: ''
};
export default styled(FiveCard).withConfig({
  componentId: "sc-1mdi61j-1"
})(["", ";"], styles);